import React, { FC, useContext } from 'react'
import styled from 'styled-components'
import { Icon } from './Generic'
import { observer } from 'mobx-react'
import { StoreContext } from './App'

interface ButtonProps {
  margin?: string
  bgColor?: string
  color?: string
  borderColor?: string
  bgColorHover?: string
  colorHover?: string
  borderColorHover?: string
  bgColorDisabled?: string
  colorDisabled?: string
  borderColorDisabled?: string
  fontSize?: string
}

const Button = styled.button<ButtonProps>`
  padding: 0px 16px;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  outline: none;
  cursor: pointer;
  border-radius: 100px;
  font-size: ${(props) => props.fontSize || '14px'};

  margin: ${(props) => (props.margin ? props.margin : '0')};
  background-color: ${(props) => props.bgColor || '#2a0062'};
  color: ${(props) => props.color || '#fff'};
  border: 1px solid ${(props) => props.borderColor || '#2a0062'};

  &:hover {
    background-color: ${(props) => props.bgColorHover || '#fff'};
    color: ${(props) => props.colorHover || '#2a0062'};
    border: 1px solid ${(props) => props.borderColorHover || '#2a0062'};
  }

  &:disabled {
    background-color: ${(props) => props.bgColorDisabled || '#ccc'};
    color: ${(props) => props.colorDisabled || '#fff'};
    border: 1px solid ${(props) => props.borderColorDisabled || '#ccc'};
    cursor: not-allowed;
  }

  @media (max-width: 980px) {
    min-width: 44px;
    padding: 0;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    span {
      display: none;
    }
  }
`

interface DownloadDocumentButtonProps {
  file?: string // base64 string or url
  fileName?: string
  onClick?: () => void
  margin?: string
}

export const DownloadDocumentButton: FC<DownloadDocumentButtonProps> = observer(
  ({ file, fileName, onClick, margin }) => {
    const store = useContext(StoreContext)
    const { theme } = store.InterfaceState

    const downloadFile = () => {
      let blobUrl: string | null = null

      if (file.startsWith('data:')) {
        // Handle data URI
        const base64Data = file.split(',')[1]
        const contentType = file.substring(
          file.indexOf(':') + 1,
          file.indexOf(';')
        )

        const byteCharacters = atob(base64Data)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: contentType })
        blobUrl = URL.createObjectURL(blob)
      } else if (file.startsWith('http')) {
        // Handle URL
        // blobUrl = file

        //open tab
        window.open(file, '_blank', 'noopener,noreferrer')
        return
      } else {
        // Handle base64 string without data URI
        const byteCharacters = atob(file)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'application/pdf' })
        blobUrl = URL.createObjectURL(blob)
      }

      const link = document.createElement('a')
      link.href = blobUrl!
      link.download = fileName || 'document.pdf'

      // Append to body to make it work in Firefox
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // Revoke the object URL to free up memory
      if (blobUrl && blobUrl.startsWith('blob:')) {
        URL.revokeObjectURL(blobUrl)
      }
    }

    return (
      <Button
        onClick={onClick ? onClick : downloadFile}
        margin={margin}
        {...theme.downloadDocumentButton}
        fontSize={theme.button.fontSize}
      >
        <span>Download document</span>
        <Icon
          color={'currentColor'}
          colorHover={'currentColor'}
          type="download"
          size="24px"
        />
      </Button>
    )
  }
)
