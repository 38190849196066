import React, { CSSProperties, FC, useContext, useEffect, useRef } from 'react'
import { Page } from 'react-pdf'
import { FixedSizeList as List } from 'react-window'
import { InnerWrap } from './PdfViewer.styles'
import { observer } from 'mobx-react'
import { StoreContext } from '@/components/App'
import { devLog } from '@/methods/devLog'

interface RowProps {
  index: number
  style: CSSProperties
}

interface PageRendererProps {
  numPages: number
  searchText?: string
  pageClassName: string
  canvasWidth: number
  pageScale: number
  containerDimensions?: { width: number; height: number }
  viewerWidth?: string | number
  viewerHeight?: string | number
  onDocumentLastPage?: () => void
}

export const PageRenderer: FC<PageRendererProps> = observer(
  ({
    numPages,
    pageClassName,
    canvasWidth,
    pageScale,
    containerDimensions,
    viewerWidth,
    viewerHeight,
    onDocumentLastPage,
  }) => {
    const store = useContext(StoreContext)
    const {
      pageBackground,
      scrollTrackColor,
      scrollHandleColor,
      scrollRadius,
    } = store.InterfaceState.theme.pdfViewer

    // Render function for react-window
    const ReactWindowPage = ({ index, style }: RowProps) => {
      const pageRef = useRef(null)

      useEffect(() => {
        // Cleanup function when the component unmounts
        return () => {
          if (pageRef.current) {
            pageRef.current.cleanup() // Dispose of page resources
            pageRef.current = null // Clear the reference
          }
        }
      }, [])

      return (
        <div style={style}>
          <Page
            devicePixelRatio={Math.min(1, window.devicePixelRatio)}
            className={pageClassName}
            pageNumber={index + 1}
            canvasBackground={pageBackground}
            width={canvasWidth}
            scale={pageScale}
            onLoadSuccess={(page) => {
              pageRef.current = page
            }}
          />
        </div>
      )
    }

    const onScrollHandler = (e) => {
      const totalHeight =
        (numPages - 0.8) * containerDimensions.width * 1.414 * pageScale
      const bottom = totalHeight > 0 && e.scrollOffset >= totalHeight

      devLog('PDF scroll detection: ', e.scrollOffset, totalHeight)
      devLog('PDF scroll detection: ', bottom)

      if (bottom) {
        onDocumentLastPage && onDocumentLastPage()
      }
    }

    return (
      <InnerWrap
        viewerWidth={viewerWidth}
        viewerHeight={viewerHeight}
        scrollTrackColor={scrollTrackColor}
        scrollHandleColor={scrollHandleColor}
        scrollRadius={scrollRadius}
      >
        <List
          height={containerDimensions.height}
          itemSize={containerDimensions.width * 1.414 * pageScale}
          itemCount={numPages}
          width={containerDimensions.width}
          onScroll={onScrollHandler}
          overscanCount={1}
        >
          {ReactWindowPage}
        </List>
      </InnerWrap>
    )
  }
)
