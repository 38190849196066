import {
  Button,
  ContentContainer,
  Header,
  Icon,
  LoadingSpinner,
  PdfViewer,
} from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { StoreContext } from '../components/App'
import { axiosInstance } from '../methods/axiosConfig'

import { actionReport } from '../methods/actionReport'
import { devLog } from '../methods/devLog'
import {
  ButtonText,
  CheckboxForm,
  ZoomControlWrap,
} from '../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentToRight,
} from '../styles/layout.styles'
import { getViewerHeight } from '../styles/utils'
import { DownloadDocumentButton } from '@/components/DownloadDocumentButton'
import getUserAgent from '@/methods/getUserAgent'
import { apiUrl } from '@/constants/apiUrl'

export const SignDocumentPage = observer(() => {
  const store = useContext(StoreContext)

  const { screenSize } = store.AppState
  const { theme } = store.InterfaceState
  const { currentPageIndex, currentScenarioId, context } = store.ScenarioState
  const { signDocumentPage: trans } = store.TranslationsState.translations
  const documentNeedsScrolling = context?.forceScrollingBeforeDocumentAccept

  const [documentScrolled, setDocumentScrolled] = useState(
    !documentNeedsScrolling
  )

  const [signed, setSigned] = useState(false)
  const [docUrl, setDocUrl] = useState('')
  const [loadState, setLoadState] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)
  const [containerHeight, setContainerHeight] = useState('')
  const headerRef = useRef(null)
  const footerRef = useRef(null)

  useEffect(() => {
    if (screenSize.width && screenSize.width <= 980) {
      setMobileVersion(true)
      setContainerHeight(
        `calc(${window.innerHeight}px - ${footerRef.current.offsetHeight}px)`
      )
    }
  }, [screenSize])

  const getDocument = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL
        ? process.env.WEB_API_URL
        : 'https://dev-02-web-api.attempt.signicat.io'

      const res = await axiosInstance.get(
        `${apiUrl}/electronic-id/pdf-document-presigned`,
        {
          withCredentials: true,
        }
      )

      setDocUrl(res.data.signedUrl)

      actionReport({
        type: 'event.onboarding-web.agreement.AGREEMENT_ACKNOWLEDGED',
        payload: {},
      })
    } catch (e) {
      devLog(e)
    }
  }

  const handleProceed = async () => {
    try {
      if (currentScenarioId !== 'localTests') {
        setBtnLoading(true)
        const apiUrl = process.env.WEB_API_URL

        await axiosInstance.post(
          `${apiUrl}/electronic-id/acceptance-agreement`,
          {},
          { withCredentials: true }
        )

        setBtnLoading(false)
      }

      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    } catch (e) {
      devLog(e)
    }
  }

  useEffect(() => {
    if (!loadState) {
      setLoadState(true)
    }
  }, [])

  function simulateClick(e) {
    e.click()
  }

  useEffect(() => {
    if (loadState) {
      getDocument()
    }
  }, [loadState])

  useEffect(() => {
    if (signed === true) {
      actionReport({
        type: 'event.onboarding-web.agreement.AGREEMENT_CONFIRMED',
        payload: {},
      })
    }
  }, [signed])

  const [shouldNextPage, setShouldNextPage] = useState(false)
  const [shouldPrevPage, setShouldPrevPage] = useState(false)
  const [shouldZoomIn, setShouldZoomIn] = useState(false)
  const [shouldZoomOut, setShouldZoomOut] = useState(false)

  const zoomButtonProps = {
    ...theme.pdfViewer.zoomButton,
    border: '1px solid' + theme.pdfViewer.zoomButton.borderColor,
    borderHover: '1px solid' + theme.pdfViewer.zoomButton.borderColorHover,
    borderRadius: '50%',
    width: '44px',
    height: '44px',
    widthMobile: '44px',
    heightMobile: '44px',
    paddingMobile: '0px',
    padding: '0px',
  }

  const userAgent = getUserAgent()
  const { browser, system } = userAgent
  const shouldChangeRenderingType = browser === 'safari' || system === 'ios'

  const downloadFile = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${apiUrl}/electronic-id/pdf-document-presigned`,
        {
          withCredentials: true,
        }
      )

      if (data.signedUrl) {
        window.open(data.signedUrl, '_blank', 'noopener,noreferrer')
      }
    } catch (error) {
      devLog(error)
    }
  }

  return (
    <ContentContainer
      {...theme.container}
      width="1000px"
      minHeight="250px"
      paddingMobile="20px"
      heightMobile={containerHeight}
      marginMobile="0px"
      ref={simulateClick}
    >
      <Header
        {...theme.header}
        fontFamily={theme.globals.fontFamilyHeadline}
        flexSpaceBetween
      >
        {!mobileVersion && <div />}
        <span ref={headerRef}>{trans.header}</span>

        <ZoomControlWrap>
          <Button onClick={() => setShouldZoomOut(true)} {...zoomButtonProps}>
            <Icon {...theme.pdfViewer.zoomButton} type="zoom-out" size="30px" />
          </Button>

          <Button onClick={() => setShouldZoomIn(true)} {...zoomButtonProps}>
            <Icon {...theme.pdfViewer.zoomButton} type="zoom-in" size="30px" />
          </Button>
        </ZoomControlWrap>
      </Header>
      {mobileVersion ? (
        <PdfViewer
          file={docUrl}
          {...theme.pdfViewer}
          viewerWidth="100%"
          containerHeight={getViewerHeight(
            screenSize,
            headerRef.current.offsetHeight,
            footerRef.current.offsetHeight
          )}
          viewerHeight="100%"
          initialScale={1}
          onDocumentLastPage={() => setDocumentScrolled(true)}
          hideZoomControls
          controls={{
            shouldNextPage,
            setShouldNextPage,
            shouldPrevPage,
            setShouldPrevPage,
            shouldZoomIn,
            setShouldZoomIn,
            shouldZoomOut,
            setShouldZoomOut,
          }}
        />
      ) : (
        <PdfViewer
          file={docUrl}
          {...theme.pdfViewer}
          viewerWidth="100%"
          viewerHeight={getViewerHeight(screenSize)}
          initialScale={1}
          onDocumentLastPage={() => setDocumentScrolled(true)}
          hideZoomControls
          controls={{
            shouldNextPage,
            setShouldNextPage,
            shouldPrevPage,
            setShouldPrevPage,
            shouldZoomIn,
            setShouldZoomIn,
            shouldZoomOut,
            setShouldZoomOut,
          }}
        />
      )}
      <ContentContainerBottomFixed ref={footerRef}>
        <CheckboxForm>
          <input
            type="checkbox"
            name="sign"
            value={signed ? 'true' : 'false'}
            id="sign"
            onChange={() => setSigned(!signed)}
          />
          <label className="label-text" htmlFor="sign">
            {trans.checkboxLabel}
          </label>
        </CheckboxForm>
        <ContentToRight>
          <DownloadDocumentButton onClick={downloadFile} margin="0 20px 0 0" />

          {!documentScrolled && shouldChangeRenderingType ? (
            <Button
              onClick={() => {
                setShouldNextPage(true)
              }}
              {...theme.button}
              minWidth="224px"
              paddingMobile="14px 40px"
              id="page-submit"
            >
              {btnLoading ? (
                <LoadingSpinner
                  width="16px"
                  {...theme.loadingSpinner}
                  padding="0 0"
                  thickness={2}
                />
              ) : (
                <ButtonText>{trans?.nextPage}</ButtonText>
              )}
            </Button>
          ) : (
            <Button
              onClick={handleProceed}
              {...theme.button}
              disabled={!signed || !documentScrolled || docUrl.length === 0}
              minWidth="224px"
              paddingMobile="14px 40px"
              id="page-submit"
            >
              {btnLoading ? (
                <LoadingSpinner
                  width="16px"
                  {...theme.loadingSpinner}
                  padding="0 0"
                  thickness={2}
                />
              ) : (
                <ButtonText>{trans.proceed}</ButtonText>
              )}
            </Button>
          )}
        </ContentToRight>
      </ContentContainerBottomFixed>
    </ContentContainer>
  )
})
