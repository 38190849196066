import { action, makeAutoObservable } from 'mobx'
import { amexTheme } from '../constants/themes/amex.theme'
import { bmwTheme } from '../constants/themes/bmw.theme'
import { bondoraTheme } from '../constants/themes/bondora.theme'
import { docuTheme } from '../constants/themes/docuTheme'
import { ikanoTheme } from '../constants/themes/ikano.theme'
import { ikanoFleksTheme } from '../constants/themes/ikanoFleks.theme'
import { myPosTheme } from '../constants/themes/myPos.theme'
import { miniTheme } from '../constants/themes/mini.theme'
import { signicatTheme } from '../constants/themes/signicat.theme'
import { teamsTheme } from '../constants/themes/teams.theme'
import { googleTheme } from '../constants/themes/google.theme'
import { pinkTheme } from '../constants/themes/pink.theme'
import { mastercardTheme } from '../constants/themes/mastercard.theme'
import { santanderTheme } from '../constants/themes/santander.theme'

import { Theme } from '../constants/themes/theme'
import { devLog } from '../methods/devLog'

const themes = {
  amexTheme,
  bmwTheme,
  bondoraTheme,
  ikanoTheme,
  ikanoFleksTheme,
  myPosTheme,
  miniTheme,
  signicatTheme,
  teamsTheme,
  docuTheme,
  googleTheme,
  pinkTheme,
  mastercardTheme,
  santanderTheme,
}

export type ThemeNames = keyof typeof themes

export class InterfaceStore {
  rootStore
  themeId: ThemeNames
  theme: Theme
  sendBeforeunloadEvent: boolean = true

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setTheme(theme: ThemeNames): void {
    devLog('setTheme: ', theme)
    this.themeId = theme
    this.theme = themes[theme]
  }

  @action.bound setSendBeforeunloadEvent(sendEvent: boolean): void {
    this.sendBeforeunloadEvent = sendEvent
  }
}
